.search-bar
{
    width: 15vw;
    margin-right: 4vw;
    position: relative;
}
.search-bar>input
{
    width: 15vw;
    border-radius: 10px;
    border: none;
    background-color: #D9D9D9;
    padding: 0.7vw 1vw 0.7vw 3vw;
    font-size: 0.8vw;
}
.search-bar>img
{
    width: 1.2vw;
    position: absolute;
    left: 12px; 
    padding: 0.7vw 2vw 0.7vw 0.5vw;
    top: 50%;
    transform: translateY(-50%);
    
}