  .collapse-btn {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
  

  .org-chart-container {
    /* width: 100%; */
    width: 100vw; 
    overflow-x: auto; 
    /* overflow-y: hidden;  */
     /* white-space: nowrap;  */
    scrollbar-width: none; /*Firefox*/
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    /*  For Chrome, Edge, and Safari */
    /* &::-webkit-scrollbar {
      display: none; 
    } */
  }

.org-node {
    width: 200px;
    border-radius: 10px;
    background-color: white;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    text-align: center;
    /* padding: 10px; */
    margin: 0 auto;
    position: relative;
    padding-bottom: 10px;
    margin-top: 2vw;
    margin-bottom: 12px;
  }
  
  .org-node-header {
    background-color: #8F00FF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-top: 5px;
    height: 10px;
  }
  
  .org-node-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-top: -25px;
    border: 3px solid white;
  }
  
  .org-node-body {
    padding-top: 10px;
  }
  
  .org-node-title {
    font-size: 12px;
    margin-bottom: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-top: 5px;
  }

  .org-node-title span {
    display: inline-block;
    max-width: 180px;
    min-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
  }

  
  .org-node-subtitle,
  .org-node-location,
  .org-node-department span {
    display: inline-block;
    max-width: 180px;
    min-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
  }

  
  .org-node-subtitle,
  .org-node-location,
  .org-node-department {
    font-size: 10px;
    color: #666;
    margin-bottom: 3px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    /* line-height: 5px; */
  }

  
  .org-node-footer {
    margin-top: 10px;
  }
  
  .badge {
    background-color: #4a90e2;
    color: white;
    padding: 5px 10px;
    border-radius: 6px;
    font-size: 14px;
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    height: 18px;
    cursor: pointer;
  }
