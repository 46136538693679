.middle-bar-bg
{
    width: 64vw;
    display: flex;
    flex-direction: column;
}
.upper-middle
{
    height:29vw;
    background-color: white;
    border-radius: 20px;
    margin: 0 0 0.5vw 0.5vw;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.15);

}
.upper-middle>p
{
    font-family: Poppins;
    font-weight: 700;
    font-size: 1.3vw;
    padding: 0 0 0 1vw;
    margin-block-end: 0.5vw;
}
.upper-middle>div
{
    display: flex;
    justify-content: center;
    gap: 1vw;
}
.col
{
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 0.5vw;
}
#box1
{
    border-radius: 20px;
    border: 1px solid #CACACA;
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content:flex-start;
    gap: 0.5vw;
    padding: 1vw 0 1vw 0;
}
#box1>span,  #box2>span
{
    font-family: Poppins;
    font-weight: 600;
    font-size: 1vw;
    align-self: flex-start;
    padding: 1vw 0 0.5vw 1vw;
}
#box1>div
{
    width: 85%;
    padding: 0.3vw 0;
    border-radius: 40px;
    background: #5937C8;
    display: flex;
    align-items: center;
}
.empDP
{
    width: 2.2vw;
    height: auto;
    border-radius: 50%;
    margin: 0 1vw 0 0.5vw;
}
.empName
{
    font-family: Poppins;
    font-size: 0.8vw;
    color: white;
}
#box2
{
    height: 8vw;
    border-radius: 20px;
    border: 1px solid #CACACA;
    padding: 1vw 0 0 0;
}
#box2> div
{
    width: 80%;
    border-radius: 50px;
    background: #E6E1F7;
    display: flex;
    align-items: center;
    margin: 1vw 2vw 1vw 1vw;
    padding: 1vw;
}
.bell-icon
{
    width: 1.5vw;
    margin: 0 1vw 0 0.5vw;
}
.notification-header
{
    font-family: Poppins;
    font-size: 0.9vw;
}
#box3
{
    height: 16vw;
    border-radius: 20px;
    border: 1px solid #CACACA;
    background: #E6E1F7;
}
#box3>img
{
    width: 100%;
    height: 70%;
    border-radius: 20px;
}
#box3>h4, #box3>p
{
    text-align: center;
    font-family: Poppins;
    font-size: 1vw;
    margin-block-start: 0.5vw;
    margin-block-end: 0.2vw;
}
#box3> p
{
    margin-block-start: 0;
    font-size: 0.8vw;
}
#box4
{
    height: 8vw;
    border-radius: 20px;
    border: 1px solid #CACACA;
    background: #5937C8;
}
#box4>div
{
    height: 50%;
    background: #E6E1F7;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.10);
    border-radius: 20px;
}
#box4>div>h4, #box4>div>p
{
    margin-block-start: 0;
    margin-block-end: 0;
    padding: 0.5vw 0 0 0;
    text-align: center;
    font-family: Poppins;
    font-size: 0.9vw;
}
#box4>div>p
{
    font-size: 0.8vw;
    padding: 0;
}
#box4>p
{
    font-family: Poppins;
    font-weight:lighter !important;
    font-size: 0.8vw;
    color: white;
    text-align: center;
    margin-block-start: 0.5vw;
    margin-block-end: 0;
}
#box5
{
    height: 6.5vw;
    background: #5937C8;
    border-radius: 20px;
    border: 1px solid #CACACA;
}
#box5> p
{
    font-family: Poppins;
    color: white;
    font-size: 1vw;
    padding: 0.5vw 0 0 0;
    margin-block-start: 0;
    margin-block-end: 0;
    text-align: center;
}
#box5> div
{
    padding: 0.5vw;
    background-color: #E6E1F7;
    border-radius: 20px;
    width: 80%;
    border-radius: 50px;
    display: flex;
    align-items: center;
    margin: 0.5vw 2vw 1vw 1vw;
    padding: 0.8vw;
}
#box5> div>span
{
    font-family: Poppins;
    font-size: 0.8vw;
}
#box6
{
    height: 17.5vw;
    border-radius: 20px;
    border: 1px solid #CACACA;
}
#box6> img
{
    width: 100%;
    height: 80%;
    border-radius: 20px;
}
#box6> h4
{
    font-family: Roboto;
    color: #5937C8;
    font-size: 1vw;
    text-align: center;
    margin-block-start:0;
    margin-block-end: 0;
}
#box6> p
{
    font-family: Poppins;
    text-align: center;
    font-size: 0.8vw    ;
    margin-block-start:0;
    margin-block-end: 0;
}
.lower-middle
{
    height:7vw;
    background-color: white;
    border-radius: 20px;
    margin: 0 0 1vw 0.5vw;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content:space-evenly;
    align-items: center;
}
.lower-middle> div
{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1vw 0 0.5vw 0;
    cursor: pointer;
}
.lower-middle>div> div
{
    width: 3vw;
    height: 3vw;
    border-radius: 50%;
    background-color: #E6E1F7;
    display: flex;
    justify-content: center;
    align-items: center;

}
.lower-middle>div> div>img
{
    width: 60%;
}
.lower-middle>div> p
{
    width: 60%;
    text-align: center;
    margin-block-start: 0;
    margin-block-end: 0;
    font-family: Poppins;
    font-size: 0.7vw;
}

