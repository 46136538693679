.cards-bg
{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2em;
}

.card
{
    width: 12vw;
    max-height: 4vw;
    background-color: #8F00FF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3vw;
    box-shadow: 3px 3px 3px 4px #a9a7a7;
}
.card-header
{
    display: flex;
    justify-content: flex-start ;
    align-items: center;
    gap:1vw;
    width: 50%;
    margin-block-start: 0;
    margin-block-end: 0;
}
.card-header img
{
    width: 3.5vw;
    height: 3.5vw;
}
.card-header>h2
{
    font-family: Poppins;
    font-size: 0.75vw !important;
    font-weight: 300;
    color: white;
    text-align: center;
}
.card hr
{
    height: 1px;
    width: 100%;
    background-color: white;
}
.courses-status
{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.9vw;
    height: 40%;
    width: 100%;
    /* margin-block-start: 0 !important; */
    margin-block-end: 0 !important;
}
.courses-status div
{
   margin-top: 0.5em;
}
.card>h2, .card>p, .courses-status>div>p
{
    color: white;
    font-family: Poppins;
    font-size: 0.75vw;
    text-align: center;
    margin-block-start: 0;
    margin-block-end: 0;
}
