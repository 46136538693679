.profile-bg {
    min-height: 88.25vh;
    background-color: #8F00FF;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upper-section {
    width: 50%;
    height: 10vh;
    background-color: white;
    margin-bottom: 3vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1vw;
    border-radius: 0.5vw;
    position: relative;
}

.upper-section p {
    font-family: Poppins;
    font-weight: 700;
    font-size: 1em;
    padding: 1em 0 1em 2em;
}

.upper-section .upper-section-buttons {
    display: flex;
    gap: 1vw;
}

.cancel,
.save {
    width: 50%;
    padding: 0.8vw;
    border: none;
    color: white;
    border-radius: 2vw;
}

.cancel {
    background-color: #C5C5C5;
    margin-right: 1vw;
}

.save {
    background-color: #8E00FD;
}

.up-lower-section {
    width: 50%;
    background-color: white;
    border-radius: 0.5vw;
    padding-bottom: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.up-lower-section p:first-child {
    font-family: Poppins;
    font-weight: 600;
    font-size: 1em;
    padding: 1em 0 0 2em;
}

.personal-details {
    width: 90%;
    display: flex;
    justify-content: space-around;
    margin-top: 1em;
}

.left-column,
.middle-column,
.right-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
}

.dp {
    height: 9vw;
    width: 10vw;
    border-radius: 50%;
    margin-top: 1vw;
}

.left-column input[type="file"] {
    margin-top: 1vw;
    margin-left: 5vw;
}

.middle-column div,
.right-column div {
    margin-bottom: 1vw;
    width: 100%;
}

.middle-column label,
.right-column label {
    font-size: 0.7vw;
    font-weight: 600;
    margin-bottom: 0.5vw;
}

.middle-column input,
.right-column input {
    width: 80%;
    padding: 0.7em;
    border: none;
    border-radius: 0.5vw;
    background-color: #D9D9D9;
}

.fgt-pwd {
    margin-top: 0.25vw;
    margin-left: 10vw;
    text-align: center;
    font-family: Poppins;
    font-weight: 300;
    font-size: 0.75em;
}

.error-text {
    color: red;
    /* Red color for errors */
    font-size: 12px;
    /* Small font size to fit under input */
    /* margin-top: 5px; */
    /* Space above the error message */
    margin-left: 2px;
    /* Slight space to the left for alignment */
    display: block;
    /* Ensures the message is displayed as a block */
}