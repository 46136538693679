.assigned-courses>h1 {
    font-family: Poppins;
    font-size: 2vw;
}

.courses-list {
    display: flex;
    gap: 2.5vw;
}

.courses-list>div {
    width: 21vw;
    height: 16vw;
    position: relative;
}

.course-thumbnauil-img {
    width: 100%;
    border: solid 1px rgb(149, 142, 142);
    border-radius: 10px;
}

.courses-list>div>p {
    position: absolute;
    top: 5%;
    left: 5%;
    font-family: Poppins;
    font-size: 2vw;
    font-weight: 600;
    color: black;
}