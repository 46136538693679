.homepage-bg
{
    background-image: url('../images/homepageBG.png');
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 0.5em;
    position: relative;
}
.greeting-div
{
    position: absolute;
    bottom: 39vw;
    left: 20vw;
}
.greeting-div> p
{
    font-family: Poppins;
    font-weight: 600;
    font-size: 1.5vw;
    color: #5937C8;
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 1.5vw;
}
.greeting-div> h2
{
    font-family: Poppins;
    font-weight: 800;
    font-size: 2vw;
    margin-block-start: 0;
    margin-block-end: 0;
}
#profile-div
{
    position: absolute;
    width: 16vw;
    /* height: 4vw; */
    border-radius: 50px;
    padding: 0.3vw 0 ;
    right: 1vw;
    bottom: 41vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
}
.user-pp
{
    width: 2.5vw;
    border-radius: 50%;
    margin-left: 0.5vw;
}
#profile-div > div
{
    width: 2.2vw;
    height: 2.2vw;
    border-radius: 50%;
    border: 1px solid #CACACA;;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5vw;
}
#profile-div>  p
{
    width: 7vw;
    margin-block-start: 0;
    margin-block-end: 0;
    font-family: Poppins;
    font-size: 0.9vw;
}
#profile-div > div> img
{
    width: 1.2vw !important;
}