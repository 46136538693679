.dashboard-container{
  display: flex;
    overflow-y: hidden;
}
.dashboard-bg
{
    display: flex;
    flex-direction: column;
    margin-left: 3vw;
    position: relative;
    align-items: flex-start;
    width: 80vw;
}
.blur
{
    filter: blur(20px);
}
.dashboard-bg >div
{
    margin: 2vw 0 ;
}
/* for assigned and available courses list */
.dashboard-bg>div:nth-child(4),
.dashboard-bg>div:nth-child(5){
  margin: -1vw 0;
}
/* .dashboard-bg>div:nth-child(5) {
  margin: -1vw 0;
} */
.welcome-text
{
    font-family: Poppins;
    font-size: 2.5vw;
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 1.3em;
    margin-top: 0vw;
}
.welcome-desc
{
    font-family: Poppins;
    font-size: 1.5vw;
    letter-spacing: -1px;
    margin-block-start: 0.5em;
    margin-block-end: 0;
}


/* Add this to your style.css */

.notification-container {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 1em;
  }
  
  .notification-item {
    padding: 5px;
    margin: 5px 0;
    cursor: pointer;
    /* Add additional styles as needed */
  }
  
  /* Additional styles for search and profile dropdown */
  .search-dropdown {
    /* Add styles for the search dropdown */
  }
  
  .profile-dropdown {
    /* Add styles for the profile dropdown */
  }
  