.course-creation-bg {
  display: flex;
  /* overflow-y: hidden; */
  /* overflow-y:hidden; */
  flex-direction: row;
}

.course-creation-bg > .cc-right-section {
  width: 80vw;
}
.course-creation-bg > .cc-right-section > span {
  font-family: Poppins;
  font-weight: 500;
  font-size: 2.5vw;
  padding: 0 0 0 2.5vw;
}


.right-section>span{
  font-size: 1.25vw;
  font-family: Poppins;
  font-weight: bold;
  margin-left: 2vw;
  margin-bottom: 1vw;
}