.create-user-profile-bg {
  display: flex;
  overflow-y: hidden;
}

.right-section {
  width: 80vw;
  /* height: 100vh; */
  /* background-color: pink; */
}
.right-section > h1 {
  font-size: 2vw;
  /* margin-block-end: 0; */
  margin-block-start: 0;
  padding: 0 0 0 1.5vw;
  margin-left: 0.5vw;
}

.user-info-outer-cont {
  height: 35vw;
  width: 79vw;
  margin-left: 2vw;
  background-color: #dfdfdf;
  border-radius: 1vw;
}

.user-info-header {
  display: flex;
  flex-direction: row;
  height: 4vw;
  width: 77vw;
  margin-left: 1vw;
  margin-top: 1vw;
  /* background-color: blue; */
  border-radius: 1vw;
}
.user-info-header > span {
  color: #8f00ff;
  font-family: Poppins;
  font-size: 1.25vw;
  margin-top: 1.75vw;
  margin-left: 0.75vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.user-info-header > .cancel {
  width: 9vw;
  height: 2.5vw;
  text-align: center;
  padding: 0.5vw 1vw;
  background-color: #797979;
  border: none;
  border-radius: 1vw;
  color: white;
  font-family: Poppins;
  font-size: 0.7vw;
  margin-left: 45vw;
  margin-top: 1.75vw;
  cursor: pointer;
}

.user-info-header > button:last-child {
  width: 9vw;
  height: 2.5vw;
  text-align: center;
  padding: 0.5vw 1vw;
  background-color: #8f00ff;
  border: none;
  border-radius: 1vw;
  color: white;
  font-family: Poppins;
  font-size: 0.7vw;
  margin-left: 1vw;
  margin-top: 1.75vw;
  cursor: pointer;
}

.user-info-body {
  height: 29vw;
  width: 75.5vw;
  margin-left: 1vw;
  /* background-color: orange; */
  background-color: white;
  margin-top: 1.25vw;
  border-radius: 1vw;
  display: flex;
  flex-direction: row;
}
.user-info-body-left-cont {
  display: flex;
  flex-direction: column;
  min-width: 20vw;
  max-width: 20vw;
  margin-left: 2vw;
  /* background-color: orange; */
  margin-top: 0.5vw;
  margin-bottom: 0.5vw;
}

.user-info-body-mid-cont{
  display: flex;
  flex-direction: column;
  min-width: 26vw;
  max-width: 26vw;
  /* margin-left: 9vw; */
  /* background-color: white; */
  margin-top: 0.5vw;
  margin-bottom: 0.5vw;
}

.user-info-body-right-cont {
  display: flex;
  flex-direction: column;
  min-width: 26vw;
  max-width: 26vw;
  /* margin-left: 9vw; */
  /* background-color: green; */
  margin-top: 0.5vw;
  margin-bottom: 0.5vw;
}

.user-info-body-mid-cont {
  /* margin-top: 2.5vw; */
}

.user-info-body-left-cont > div,
.user-info-body-mid-cont > div,
 .user-info-body-right-cont>div{
  display: flex;
  flex-direction: column;
  margin-left: 2.5vw;
  /* width: 15vw; */
}
.labels {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
      /* height: 3vw; */
  height: 1.5vw;
  margin-top: 1vw;
  align-items: center;
  text-wrap: nowrap;
}
.labels>label:last-child,
.labels>label:last-child {
  color: #d41515;
}
/* .user-info-body-left-cont > div > label,
.user-info-body-mid-cont > div > label {
  color: #000000;
  height: 2vw;
} */

.user-info-body-left-cont > div > input,
.user-info-body-mid-cont > div > input,
.user-info-body-right-cont>div>input {
  height: 3vw;
  width: 20vw;
  background-color: #d9d9d9;
  border: none;
  border-radius: 0.5vw;
}

.user-info-body-mid-cont > div > label,
.user-info-body-right-cont>div>label {
  color: #000000;
  height: 2vw;
  margin-top: 1.25vw;
  margin-bottom: -0.75vw;
}

.user-info-body-left-cont>div>label{
  color: #d41515;
    height: 2vw;
    margin-top: 1.25vw;
    margin-bottom: -0.75vw;
    margin-left: 1.5vw;
}

.top-div {
  margin-top: 7.75vw;
}
.user-info-body-left-cont > div > img {
  /* margin-top: 5vw;
  margin-left: 1.75vw; */
}
.user-info-body-left-cont > div > button {
  width: 11vw;
  margin-left: 1.9vw;
  text-align: center;
  padding: 0.5vw 1vw;
  background-color: #8f00ff;
  border: none;
  border-radius: 5px;
  color: white;
  font-family: Poppins;
  font-size: 0.7vw;
  margin-top: 0.5vw;
  cursor: pointer;
}
.uploadedUserProfile {
  width: 11vw;
  height: 11vw;
  border-radius: 50%;
  margin-top: 5vw;
  margin-left: 1.75vw;
}

.camera-icon {
  width: 11vw;
  height: 11vw;
  border-radius: 50%;
  margin-top: 5vw;
  margin-left: 1.75vw;
}


.user-info-body-mid-cont>div>select,
.user-info-body-right-cont>div>select {
  height: 3vw;
  width: 20vw;
  background-color: #d9d9d9;
  border: none;
  border-radius: 0.5vw;
}

.user-info-body-mid-cont>:last-child>.show-pwd{
  display: flex;
  flex-direction: row;
}
.user-info-body-mid-cont>:last-child>.show-pwd >input {
  height: 3vw;
  width: 16vw;
  background-color: #d9d9d9;
  border: none;
  border-radius: 0.5vw;
}

.user-info-header>.cancel,
.user-info-header>.save {
  width: 9vw;
  height: 2.5vw;
  text-align: center;
  padding: 0.5vw 1vw;
  background-color: #d41515;
  border: none;
  border-radius: 1vw;
  color: white;
  font-family: Poppins;
  font-size: 0.7vw;
  margin-left: 40.5vw;
  margin-top: 1.75vw;
  cursor: pointer;
}

.user-info-header>.save {
  background-color: #8f00ff;
  margin-left: 1vw;
}

.error-label{
  white-space: nowrap;
  font-size: 0.8vw;
  margin-left: 4px;
}