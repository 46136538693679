.changePW-bg
{
    min-height: 85vh;
    background-color: #CFCFCF;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.upper-section1
{
    width: 75%;
    height: 10vh;
    background-color: white;
    margin-bottom: 3vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.upper-section1 p
{
    font-family: Poppins;
    font-weight: 700;
    font-size: 1em;
    padding: 1em 0 1em 2em;
}
.lower-section1
{
    width: 75%;
    height: 65vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 2em;
}
.password-details
{
    margin: 0 0 2em 4em ;
    display: flex;
    flex-direction: column;
    row-gap: 2em;
    width: 20vw;
}
.password-details div label
{
    font-family: Poppins;  
    font-size: 0.8em;  
}
.password-details div input
{
    width: 100%;
    padding: 0.7em;
    border: none;
    border-radius: 5px;
    background-color: #D9D9D9;
    padding-left: 10%;
}
.lower-section1-buttons
{
    display: flex;
    justify-content: flex-end;
    width: 25vw;
    gap: 1em;
}
.lower-section1-buttons button:first-child, .lower-section1-buttons button:nth-child(2)
{
    padding: 1vh 2vw;
    font-family: Poppins;
    border-radius: 50px;
    border: none;
    color: white;
}
.lower-section1-buttons button:first-child
{
    background-color: #C5C5C5;
}
.lower-section1-buttons button:nth-child(2)
{
    background-color: #8E00FD;
}


