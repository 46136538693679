.main_container {
    box-sizing: border-box;
    margin: 0;
    width: 73vw;
    height: 100vh;
}

/* .disabled{
    cursor:none;
} */
.cursor {
    cursor: pointer;
}