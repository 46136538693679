.nav-bg
{
    width: 100%;
    height:5.5vw;
    background-color: #8F00FF;
    /* box-shadow: 3px 3px 10px 10px #616162; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nav-cpd-logo
{
    width: 10%;;
    margin-left: 3vw;
    /* border-radius: 30px; */
}
.nav-right-section
{
    display: flex;
    width: 50%;
    justify-content: end;
    align-items: center;
    margin-right: 4vw;
    gap: 3vw;
}
