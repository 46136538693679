.container {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    float: right;
}

.left-section-org-chart {
    display: flex;
    margin-right: 5vw;
}


.tab-container {
    display: flex;
}

.tab-button-orgc {
    background-color: white;
    border: none;
    padding: 10px 20px;
    /* margin-right: 5px; */
    /* border-radius: 5px; */
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 4px;
    color: black;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
}

.tab-button-orgc.active {
    background-color: #6a0dad;
    color: white;
}

.tab-button-orgc:hover {
    background-color: #6a0dad;
    color: white;
}

.right-section-container{
    align-items: center;
    justify-content: space-between;
    /* padding: 10px; */
    /* background-color: #f5f5f5;  */
    /* Light gray background */
    border-radius: 10px; /* Rounded corners */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);/* Subtle shadow */
    width: fit-content;
    background-color: #fff;
    display: flex;
    margin-right: 2vw;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 4px;
    /* color: black;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem; */
}

.group-section {
    display: flex;
    align-items: center;
    padding-left: 1vw;
}

.switch {
    position: relative;
    display: inline-block;
    width: 23px;
    height: 12px;
    margin-right: 10px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
    
}

.slider:before {
    position: absolute;
    content: "";
    height: 9px;
    width: 9px;
    left: 2px;
    bottom: 2px;
    /* background-color: white; */
    transition: .4s;
    border-radius: 50%;
    background-color: #fff;
}

input:checked + .slider {
    background-color: #6a0dad;
}

input:checked + .slider:before {
    transform: translateX(14px);
}

.label-text {
    /* font-size: 14px; */
    color: #333;
    margin-right: 20px;
    /* font-weight: bold; */
}

.download-button-orgc {
    background-color: #fff;
    /* border: 1px solid #ddd; */
    padding: 8px 16px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    color: black;
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 4px;    
}

.download-button-orgc i {
    margin-left: 5px;
}
