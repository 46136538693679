@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&family=Roboto:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Roboto:wght@900&display=swap');

.login-bg
{
    background-color: #8F00FF ;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cpd-logo
{
    width: 10%;
    position: absolute;
    right: 5%;
    top: 5%;
}
.login-form
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    /* position: absolute; */
    border-radius: 20px;
    box-shadow: 3px 3px 10px 10px #6b24a1;
    padding: 4vw 4vw;
    /* min-width: 460px; */
}
.login-header
{
    font-family: Poppins;    
    font-size: 2.2vw;
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
}

.login-desc, .error-msg, .error
{
    font-family: Poppins; 
    font-weight: 400;
    font-size: 0.8vw;
    margin-block-start: 0.5vh;
}
.error-msg
{
    color: red;
    margin-top: 1vw;
    margin-bottom: -1vw;
}
.error{
    color: red;
    width: -webkit-fill-available;
    margin-bottom: 0;
}
#email-bar, #password-bar
{
    position: relative;
    display: flex;
    justify-content: center;
    /* width: 20vw; */
    margin-top: 2vh;
}
  
#email-bar input, #password-bar input, #confirm-password-bar input
{
    width: 100%; 
    background-color: #e7e5e8;
    border-radius: 10px;
    border: 0px;
    padding: 0.9vw 2vw 0.9vw 3vw ;
    font-size: 0.8vw;
}

#email-bar img, #password-bar img, #confirm-password-bar img 
{
    position: absolute;
    left: 1vw; 
    padding-right: 1vw;
    top: 50%; 
    transform: translateY(-50%);
    width: 0.8vw;
}
.forgot-pw
{
    font-family: Poppins;
    font-size: 0.9vw;
    color: gray;
}
.login-btn
{
    width: 60%;
    padding: 0.8vw 1vw;
    background-color: #8F00FF;
    border-radius: 50px;
    font-family: Poppins;
    font-size: 0.8vw;
    color: white;
    border: none;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin: 1.5em 0 0 0;
    margin-top: 2.5vw;
    cursor: pointer;
}
.register-opt
{
    font-family: Poppins;
    font-size: 0.8vw;
}

#confirm-password-bar {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 1vw;
    margin-bottom: 2vw; 
}



/* .username-display>span{
    margin-left: 0vw;
} */

.username-display>img{
    width: 2vw;
    height: 2vw;
}

.login-fgt-pwd{
    text-align: center;
    font-family: Poppins;
    font-weight: 300;
    font-size: 0.75em;
    margin-top: 1vw;
    margin-bottom: -1vw;
}