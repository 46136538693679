
.small-profile-section
{
    width: 18vw;
    height: 26vw;
    background-color: #DBD9D9BF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.small-profile-section>img
{
    width: 7vw;
    height: 7vw;
    border-radius: 50%;
    outline: solid 1px black;
}
.small-profile-section>span
{
    font-family: Poppins;
    font-size: 1.2vw;
    padding-bottom: 0.3vw;
}
.small-profile-section>p
{
    font-family: Poppins;
    font-weight: 600;
    font-size: 1vw;
    margin-block-start: 1vw;
}
.small-profile-section>div
{
    padding: 0.2vw;
}

.course-stats
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    font-family: Poppins;
    font-size: 0.8vw;
}
