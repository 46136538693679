.user-profile-div {
    width: 2.5vw;
    height: 2.5vw;
background-color: white;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    /* overflow: hidden; */
}

.user-profile-div>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    /* margin-left: 1vw; */
}

.profile-list
{
    position: absolute;
    z-index: 2;
    right: -2vw;
    top: 5vw;
    width: 13vw;
    background-color: white;
    box-shadow: 3px 3px 3px 4px #f1f0f0;
    display:  flex;
    flex-direction: column;
    align-items: center ;
    border-radius: 1vw;
}
.profile-list>div
{
    display: flex;
    /* justify-content: space-between ; */
    align-items: center;
    width: 90%;
    cursor: pointer;
    gap: 2vw;
}
.profile-list div img
{
    width: 1.2vw;
    margin-left: 1em;
}
.profile-list div p
{
    font-family: Poppins;
    font-size: 0.9vw;
    margin-right: 1em;
}
