.course-report-bg {
    display: flex;
    overflow-y: hidden;
    width: 100vw;
    /* Ensure full width */
}

.course-report-rgt-sec {
    width: 80vw;
    /* Adjust width to fit within 80vw */
    overflow: hidden;
    /* Prevent overflow */
    height: calc(100vh - 64px);
    /* Adjust height considering navbar */
    padding: 20px;
}

.data-grid-container {
    /* max-height: calc(50vh - 40px); */
    height: calc(100% - 40px);
    /* Adjust to fit two grids within viewport */
    overflow-y: auto;
    /* margin-bottom: 10px; */
    /* Space between the tables */
}

.export-btn {
    margin-left: 16px;
}

.search-select-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    
}