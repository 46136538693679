/* Background and layout */
.apprsl-bg {
    display: flex;
    overflow-y: hidden;
    width: 100vw;
}

.apprsl-rgt-sec {
    width: 80vw;
    overflow: hidden;
    /* height: calc(100vh - 64px); */
    height: 42.5vw;
    /* background-color: green; */
    padding: 20px;
}

/* Stepper container */
.apprsl-stepper-container {
    margin-top: 0.25vw;
    padding: 1vw;
}

/* Responsive Stepper styling */
.MuiStepLabel-label {
    font-size: 0.60vw;
    /* Adjust label size using viewport width */
}

.MuiStepper-root {
    width: 80vw;
    /* Make stepper width responsive */
}

/* Scrollable content box */
.apprsl-content-box {
    height: 25vw;
    /* Set height of the content box (adjust as needed) */
    overflow-y: auto;
    /* Make the content scrollable if it's long */
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    margin-top: 1vw;
}

/* Responsive buttons */
button {
    /* font-size: 1vw; */
    /* Ensure buttons scale with the viewport */
}