.performance-evaluation-form {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: auto;
}

.section-title {
    background-color: #8a3ab9;
    color: white;
    padding: 10px;
    border-radius: 4px;
}

.performance-evaluation-form ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 20px;
}

.performance-evaluation-form li {
    margin-bottom: 5px;
}
