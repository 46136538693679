.responsibilities-form {
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    /* margin-top: 20px; */
    height: 23vw;
}

.responsibilities-form h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333;
}

/* Style the text fields */
.responsibilities-form .MuiTextField-root {
    /* margin-bottom: 16px; */
}

/* Helper text for the character counter */
.responsibilities-form .MuiFormHelperText-root {
    text-align: right;
    color: #aaa;
}

/* Buttons container styling
.buttons-container {
    margin-top: 20px;
}

.buttons-container .MuiButton-root {
    min-width: 120px;
}

.buttons-container .MuiButton-outlined {
    border-color: #333;
    color: #333;
}

.buttons-container .MuiButton-contained {
    background-color: #8a3ab9;
    color: white;
} */