.left-sidebar-bg {
  width: 17vw;
  height: 43vw;
  border-radius: 20px;
  background-color: white;
  margin: 0 0 1vw 1vw;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.15);
}

.logo-container {
  display: flex;
  justify-content: center;
  height: 5vw;
  width: 10vw;
  padding-bottom: 2.5vw;
}

.logo {
  width: 9vw;
  height: 3vw;
  padding-top: 1.5vw;
  padding-left: 5vw;
  padding-bottom: 3vw;
}

.button {
  padding-top: 2vw;
  padding: 10px;
  display: flex;
  align-items: center;
}

.button img {
  margin-right: 10px;
  width: 2vw;
  padding-left: 1vw;
  cursor: pointer;
}

.button span {
  font-family: "Roboto-Medium", Helvetica;
  padding-left: 10px;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  cursor: pointer;
}

.sign-out {
  display: flex;
  align-items: center;
  padding-top: 10vw;
  padding-left: 3vw;
}

.sign-out img {
  margin-right: 7px;
  width: 2vw;
  cursor: pointer;
}
.sign-out span {
  cursor: pointer;
}
