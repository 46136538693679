.ad-dashboard-bg {
    display: flex;
    overflow-y: hidden;
}

.ad-dash-right-section{
    width: 80vw;
}

.ad-dash-right-section>h1 {
    font-weight: 600;
    /* margin-block-end: 0; */
    margin-block-start: 0;
    padding: 0 0 0 1.5vw;
    font-size: 3vw;
}

.ad-dash-rs-body{
    min-width: 62.5vw;
    min-height: 32vw;
    background-color: white;
    margin-left: 2vw;
}

.ad-cards {
    display: flex;
    flex-direction: row;
    gap: 3vw;
    overflow-y: auto;
    max-height: 60vh;
    flex-wrap: wrap;
    overflow-y: auto;
}

.ad-cards-bg {
    /* background-color: #8F00FF; */
    background-color: #8F00FF;
    height: 12vw;
    width: 22.5vw;
    border-radius: 1vw;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    align-items:start;
    cursor: pointer;
}

.ad-cards-bg>h4{
    color: #FFFFFF;
    /* align-items: center; */
    margin-left: 2vw;
    font-size: 1vw;
}