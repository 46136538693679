.all-cards {
    display: flex;
    flex-direction: row;
    gap: 3vw;
    overflow-y: auto;
    max-height: 60vh;
    flex-wrap: wrap;
    overflow-y: auto;
  }
  
  .all-cards-bg {
    background-color: white;
    height: 12vw;
    width: 23.5vw;
    border-radius: 1vw;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
  
  .all-cards-bg > .cards-course-cover > img {
    /* background-color: green; */
    height: 9vw;
    width: 9vw;
    margin-top: 1.75vw;
    margin-left: 0.75vw;
    border-radius: 0.5vw;
  }
  .cards-course-metadata {
    height: 9vw;
    width: 12vw;
    /* background-color: black; */
    margin-top: 0.75vw;
    margin-right: 0.75vw;
    margin-left: 1.25vw;
    display: flex;
    flex-direction: column;
  }
  .cards-course-metadata > span{
    color: black;
    font-family: Poppins;
    font-size: 1vw;
  }
  .cards-course-metadata > span:nth-child(1){
    color: black;
    font-family: Poppins;
    font-size: 1vw;
    margin-top: 1vw;
    overflow: hidden;
    /* font-family: 700; */
  }
  
  /* .all-cards-bg > .cards-course-metadata {
    background-color: white;
    height: 9vw;
    width: 12vw;
    margin-left: 1vw;
    margin-top: 1.75vw;
  } */
  
  /* .all-cards-bg > .cards-course-metadata > h2 {
    margin-left: 1vw;
    color: black;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }
  
  .all-cards-bg > .cards-course-metadata > span {
    margin-left: 1vw;
    color: black;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
  } */
  
  .add-course-card-bg {
    height: 12vw;
    width: 22.25vw;
    border-radius: 1vw;
    /* background-color: #d9d9d9; */
    background-color: white;
    border-width: 5vw;
    border-color: #a2a0a0;
    border: 0.25vw solid #a2a0a0;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
  .add-course-card-bg > img {
    height: 3vw;
    width: 3vw;
    margin-left: 5vw;
  }
  
  .add-course-card-bg > span {
    color: #a2a0a0;
    /* font-family: Poppins; */
    font-size: 1.75vw;
    margin-left: 2vw;
    font-style: normal;
    font-weight: 500;
  }