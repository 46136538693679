.history-form {
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    height: 23vw;
    /* Match height to ResponsibilitiesForm */
}

.history-form h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333;
}

/* Style the text fields */
.history-form .MuiTextField-root {
    /* margin-bottom: 16px; */
}

/* Helper text for the character counter */
.history-form .MuiFormHelperText-root {
    text-align: right;
    color: #aaa;
}

.icons_div {
    display: flex;
    flex-direction: row;
    gap: 5px;
}