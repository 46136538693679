.course-list-bg{
    display: flex;
    overflow-y: hidden;
}
.coursesList-bg
{
    display: flex;
    align-items: end;
    gap: 1vw;
}
.content-section
{
    width: 78vw;
    /* height: 37vw; */
    /* background-color: peachpuff; */
}
.content-section>p
{
    font-family: Poppins;
    font-weight: 800;
    font-size: 1.5vw;
    padding: 0 0 0 2.5vw;
}

/* MUI library customizations */
.css-heg063-MuiTabs-flexContainer
{
    gap: 1vw;
}
.css-1gsv261
{
    margin: -1vw 0 0 2vw;
    border: none !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root
{
    font-family: Poppins !important;
    font-size: 1vw !important;
    color: black !important;
    font-weight: 600 !important;
    text-transform: none !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected 
{
    color: #8F00FF !important;
}
.css-1aquho2-MuiTabs-indicator
{
    background-color: #8F00FF !important;
}
.css-13xfq8m-MuiTabPanel-root 
{
    padding: 0 0 0 1vw !important;
}