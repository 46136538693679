/* .course-content-bg{
    display: flex;
    overflow-y: hidden;
    flex-direction: column;
}

.css-13xfq8m-MuiTabPanel-root
{
    padding: 0 !important;
}
.rsw-editor
{
    border: none !important;
}
.rsw-toolbar
{
    display: none !important;
}
.rsw-ce>div, .Highlight
{
    background-color: transparent !important;
}
.css-1n4fwaf-JoyMenuButton-root
{
    min-width: 10vw;
}
.abc{
    display: flex;
    flex-direction: row;
    height: 9vw;
    width: 43vw;
    background-color: red;
    margin-top: 1vw;
    margin-left: 1vw;
    border-radius: 2vw;
}

.lower-section{
    height: 19vw;
    width: 43vw;
    background-color: black;
    margin-top: 1vw;
    margin-left: 1vw;
    border-radius: 2vw;
}

#course-title-section{
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 15vw;
    height: 7vw;
    margin-left: 1vw;
}
 
#course-title-section>h5{
    font-family: Poppins;
    font-weight: 600;
    font-size: 0.9vw;
    pad>ding: 1.5vw 0 0.5vw 1.5vw;   
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0vw;
} 

#course-title-section .rsw-editor
{
    margin: 0 1.5vw;
    background-color: rgba(217, 217, 217, 0.50);
    min-height: 1vw;
    max-height: 4vw;
    overflow-y: auto;
    margin-top: 0vw;
} */

.sections-container {
  display: flex;
  flex-direction: column; /* Stack rows vertically */
}

.top-section {
  display: flex; /* Items in this row will be flex items */
}

.title-section,
.tags-section {
  width: 21vw;
  height: 7vw;
  margin: 1vw;
  /* background-color: #ccc; */
  display: flex;
  flex-direction: column;

  /* justify-content: center; */
  /* align-items: center; */
}
.title-section h5,
.tags-section h5 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 0.9vw;
  padding: 1.5vw 0 0.5vw 1.5vw;
  margin-block-start: 0;
  margin-block-end: 0;
}

.title-section .rsw-editor,
.tags-section .rsw-editor {
  margin: 0 1.5vw;
  font-size: 0.8vw;
  background-color: rgba(217, 217, 217, 0.5);
  /* background-color: white; */

  min-height: 2.5vw;
  max-height: 2.5vw;
  width: 41vw;
  overflow-y: auto;
}

.lower-section {
  /* flex-basis: calc(100% ); Full width for the third item */
  width: 43vw;
  height: 15vw; /* Height specifically for the third grid item */
  /* margin: 1vw; */
  margin-top: 0vw;
  margin-left: 1vw;
  /* background-color: #ccc; */
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.lower-section h5 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 0.9vw;
  align-items: start;
  /* margin-bottom: 0;  */
  padding: 0.5vw 0 0.5vw 1.5vw;
  margin-top: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  top:0;
  left:0;
}

.lower-section .rsw-editor {
  margin: 0 1.5vw;
  font-size: 0.8vw;
  background-color: rgba(217, 217, 217, 0.5);
  /* background-color: white; */

  min-height: 14vw;
  max-height: 15vw;
  width: 41vw;
  overflow-y: auto;
}


.lesson-content-save-btn{
    display: flex;
    align-items: end;
    flex-direction: row;
    margin-top: 2vw;
}

.lesson-content-save-btn>button{
    width: auto;
    text-align: center;
    padding: 0.5vw 1vw;
    background-color: #8F00FF;
    border: none;
    border-radius: 5px;
    color: white;
    font-family: Poppins;
    font-size: 0.7vw;
    align-self: flex-end;
    /* margin-right: 2.5vw; */
    /* margin-right: 7vw; */
    margin-top: 0.5vw;
    width: 6vw;
}

.lesson-content-save-btn>button:first-child {
  margin-left: 30.5vw;
}
.lesson-content-save-btn>button:last-child {
  background-color: red;
  margin-left: 1vw;
  margin-right: 1vw;
}

.tags-section .tag-input-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 1.5vw;
  padding: 0.5vw;
  background-color: rgba(217, 217, 217, 0.5);
  border-radius: 5px;
  max-height: 6vw;
  /* Adjust the height as needed */
  overflow-y: auto;
}

.tags-section .tag {
  display: inline-flex;
  align-items: center;
  padding: 0vw 0vw;
  /* Adjusted padding */
  margin: 0.3vw;
  background-color: #8F00FF;
  color: white;
  border-radius: 0.25vw;
}

.tags-section .tag button {
  margin-left: 0.3vw;
  padding: 0.2vw;
  /* Adjusted padding */
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 0.3vw;
  /* Adjusted font size */
}

.tags-section input[type="text"] {
  width: 10vw;
  /* Adjust the width as needed */
  height: 1.5vw;
  /* Adjust the height as needed */
  margin: 0.3vw;
  padding: 0.3vw;
  border: none;
  border-radius: 3px;
  font-size: 0.8vw;
  /* Adjust the font size as needed */
}

.lesson-content-save-btn .disabled {
  /* background-color: grey; */
  color: #ffffff;
  cursor: not-allowed;
}