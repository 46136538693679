.result-bg {
    background-color: #d6d6d6;
    height: 82vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .result-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 3vw;
    position: relative;
  }
  .result-box>img
  {
    position: absolute;
    right: 0.5vw;
    top: 0.5vw;
    width: 1.5vw;
  }
  .result-box>h1 {
    font-family: Poppins;
    font-size: 1.5vw;
    font-weight: 600;
  }
  
  .result-box>h2 {
    font-family: Poppins;
    font-size: 1.5vw;
    font-weight: 600;
    margin-block-start: 0;
  }
  .result-box>h3
  {
    font-family: Poppins;
    font-weight: 400;
    font-size: 1vw;
  }
  .print-certificate,.module-results-button {
    width: auto;
    padding: 1vw 2vw;
    margin: 1vw 0 0 0;
    background-color: #8f00ff;
    border-radius: 50px;
    font-size: 1vw;
    color: white;
    border: none;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    cursor: pointer;
  }
  