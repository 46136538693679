.cl-bg{
    display: flex;
    /* align-items: end; */
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    margin-left:9vw;
    margin-top: -1vw;
}

.cl-content-section{
    background-color: white;
    width: 82vw;
    min-height: 35.5vw;
    max-height: 35.5vw;
    margin-top: 2vw;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}

.cl-left-section {
    background-color: white;
    /* width: 24.5vw; */
    height: 34.5vw;
    box-sizing: border-box;
    /* margin-left: 32vw; */
    /* 31.5vw */
    margin-top: 0.5vw;
    overflow-y: auto;
    border: 0.2vw solid #ccc;
    border-radius: 0.5vw;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    padding: 10px;
    border-right: 1px solid #ddd;
    /* Border separating the left section */
    /* margin-left: 1vw; */
}



.cl-mid-section {
    background-color: white;
    /* width: 40vw; */
    height: 34.5vw;
    box-sizing: border-box;
    margin-top: 0.5vw;
    overflow-y: auto;
    border-top: 0.2vw solid #ccc;
    border-bottom: 0.2vw solid #ccc;
    border-left: 0.2vw solid #ccc;
    border-radius: 0.5vw 0 0 0.5vw;
    /* Adjusted to round only top-left and bottom-left corners */
    box-shadow: -4px 0 8px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1), 4px 0 8px rgba(0, 0, 0, 0);
    /* Custom shadow */
    margin-left: 1vw;
}

.cl-right-section {
    background-color: white;
    /* width: 30vw; */
    height: 34.5vw;
    box-sizing: border-box;
    margin-right: 1vw;
    margin-top: 0.5vw;
    border-top: 0.2vw solid #ccc;
    border-bottom: 0.2vw solid #ccc;
    border-right: 0.2vw solid #ccc;
    border-radius: 0 0.5vw 0.5vw 0;
    /* Adjusted to round only top-right and bottom-right corners */
    box-shadow: -4px 0 8px rgba(0, 0, 0, 0), 0 4px 8px rgba(0, 0, 0, 0.1), 4px 0 8px rgba(0, 0, 0, 0.1);
    /* Custom shadow */
}
.cl-mid-section>div>h3{
    margin-left: 1vw;
}
.cl-mid-section>div>p {
    margin-left: 1vw;
    margin-top: 0.5vw;
    font-weight:lighter;
    font-family: Arial, sans-serif;
    font-size: 1vw;
        
}


.tab-button {
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #8E00FD;
    color: white;
    border: none;
    cursor: pointer;
    font-family: Arial, sans-serif;
    font-size: 1rem;
}

.tab-button:hover {
    background-color: #6A0084;
}

.tab-div-bg{
    width: 22vw;
    height: 18vw;
    background-color: white;
    margin-left: 3.5vw;
    /* border: 0.2vw solid #ccc;
    border-radius: 0.2vw;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.tab-div-bg-asgn{
    width: 22vw;
    height: 18vw;
    background-color: white;
    margin-left: 1.5vw;
    /* border: 0.2vw solid #ccc;
    border-radius: 0.2vw;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.asgn-cont{
    display: flex;
    flex-direction: row;
    
}
.asgn-cont-left{
    width: 15.5vw;
    height: 17.25vw;
    border-radius: 0.2vw;
    margin-top: 0.5vw;
    /* margin-left: 0.75vw; */
    /* background-color: green; */
}

.asgn-cont-right{
    width: 15.5vw;
    height: 17.25vw;
    margin-top: 0.5vw;
    margin-left: 0.5vw;
    /* background-color: red; */
    /* margin-right: 0.25vw; */
    /* background-color: red; */
}

/* #css-et1ao3-MuiTypography-root{
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 3vw;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    display: block;
} */


.course-list-item .MuiListItemText-primary {
    font-size: 1vw;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}