.course-management-bg
{
    display: flex;
    overflow-y: hidden;
}
.css-13xfq8m-MuiTabPanel-root
{
    padding: 0 !important;
}
.rsw-editor
{
    border: none !important;
}
#course-title-editor .rsw-editor
{
    margin: 0 1.5vw;
    background-color: rgba(217, 217, 217, 0.50);
    min-height: 2vw;
    max-height: 3vw;
    overflow-y: auto;
}
#course-desc-editor .rsw-editor
{
    margin: 0 1.5vw;
    font-size: 0.8vw;
    background-color: rgba(217, 217, 217, 0.50);
    min-height: 15vw;
    max-height: 15vw;
    overflow-y: auto;
}
#course-title-editor .rsw-ce{
    padding: 0 0 0 0;
}
.flex-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1.75vw;
    margin-top: 2vw;
    font-family: Poppins;
}

.flex-row>h5{
    font-family: Poppins;
    font-weight: 600;
    font-size: 0.9vw;
    /* padding: 1.5vw 0 0.5vw 1.5vw;    */
    margin-block-start: 0;
    margin-block-end: 0;
}

#course-percent-editor h5{
    font-family: Poppins;
}

#course-percent-editor .rsw-editor
{
    min-height:2.50vw;
    max-height: 2.50vw;
    width: 3vw ;
    margin: 0 1.5vw;
    margin-top: 1vw;
    background-color: rgba(217, 217, 217, 0.50);
    /* overflow-y: auto; */
}
.rsw-toolbar
{
    display: none !important;
}
.rsw-ce>div, .Highlight
{
    background-color: transparent !important;
}
.css-1n4fwaf-JoyMenuButton-root
{
    min-width: 10vw;
}
.course-create-bg
{
    width: 86.5vw;
    /* height: 38vw; */
    min-height: 38.5vw;
    max-height: 38.5vw;
    background-color: #D9D9D9;
    display: flex;
    align-items:flex-end;
    justify-content: space-evenly;
   
}
.course-details-left-container, .course-details-right-container
{
    width: 30vw;
    height: 35vw;
    background-color: white;
    box-shadow: 3px 3px 7px 3px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-bottom: 1.5vw;
}
.course-details-right-container
{
    width: 45vw;
}
.course-details-left-container>h4
{
    font-family: Poppins;
    font-size: 1.2vw;
    padding: 0 0 0 1.5vw;
}
.course-details-left-container>.label-header,
.course-details-right-container>.label-header{
    display: flex;
    flex-direction: row;
    margin-top: -1vw;
}
.course-details-left-container>.label-header>h5,
.course-details-right-container>.label-header>h5{
    font-family: Poppins;
    font-weight: 600;
    font-size: 0.9vw;
    padding: 1.5vw 0 0.5vw 1.5vw;   
    margin-block-start: 0;
    margin-block-end: 0;
}
.course-details-left-container>.label-header>span,
.course-details-right-container>.label-header>span{
    font-family: Poppins;
    font-weight: 600;
    font-size: 0.9vw;
    padding: 1.5vw 0 0.5vw 1.5vw;   
    margin-block-start: 0;
    margin-block-end: 0;
    margin-left: 9vw;
}
 .course-details-right-container>h5
{
    font-family: Poppins;
    font-weight: 600;
    font-size: 0.9vw;
    padding: 1.5vw 0 0.5vw 1.5vw;   
    margin-block-start: 0;
    margin-block-end: 0;
}
.course-details-right-container>h5:first-child{
    margin-top: 4vw;
}
.cd-image-uploader
{
    display: flex;
    align-items: end;
    gap: 1vw;
}
.cd-image-uploader> div
{
    width: 16vw;
    height: 8vw;
    border-radius: 1vw;
    margin-left: 1.5vw;
    background-color: rgba(217, 217, 217, 0.50);
    display: flex;
    justify-content: center;
    align-items: center;
}
.cd-image-uploader> button
{
    width: auto;
    text-align: center;
    padding: 0.5vw 1vw;
    background-color: #8F00FF;
    border: none;
    border-radius: 5px;
    color: white;
    font-family: Poppins;
    font-size: 0.7vw;
    cursor: pointer;
}
.icon{
    height: 2vw;
    width: 2vw;
}

.uploadedImg
{
    width: 16vw;
    height: 8vw;
    border-radius: 0.5vw;

}

.cd-button-bottom{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin-top: 4vw;
    margin-left: 6vw;
}

.cd-button-bottom>button{
    background-color: #8F00FF;
    border: none;
    border-radius: 0.5vw;
    margin-left: 1vw;
    color: white;
    font-family: Poppins;
    font-size: 0.7vw;
    height: 2vw;
    width: 5vw;
    cursor: pointer;
}

.cd-button-bottom>button:first-child{
    margin-left: 20vw;
    background-color: #C5C5C5;
}

.cd-button-bottom>button:last-child {
    margin-right: 2vw;
}

.cd-button-bottom>button:nth-child(3) {
    background-color: red;
}



.user-list-container {
    margin-left: 1.5vw;
    cursor: pointer;
  }
  
  .user-list {
    min-height: 10vw;
    max-height: 10vw;
    width: 10vw; 
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  .cd-button-bottom .disabled {
      /* background-color: grey; */
      color: #ffffff;
      cursor: not-allowed;
  }