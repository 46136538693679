.sections-container {
  display: flex;
  flex-direction: column; /* Stack rows vertically */
}

.q-upper-section {
  display: flex; /* Items in this row will be flex items */
  /* background-color: black; */
  /* margin-top: 0.5vw; */
  margin-left: 1vw;
  width: 43vw;
  height: 15vw;
}

.upper-left-section,
.upper-right-section {
  width: 20vw;
  height: 15.5vw;
  /* margin: 1vw; */
  margin-left: 1vw;
  margin-right: 1vw;
  margin-bottom: 1vw;


  /* background-color: #ccc; */
  /* background-color: blue; */
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-right: 0.5vw;

  /* justify-content: center; */
  /* align-items: center; */
}
.upper-left-section h5,
.upper-right-section h5 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 0.9vw;
  padding: 1.5vw 0 0.5vw 1.5vw;
  margin-block-start: 0;
  margin-block-end: 0;
}

.horizontal-flex {
  display: flex;
  flex-direction: row;
}

.upper-left-section .rsw-editor {
  margin: 0 1.5vw;
  font-size: 0.8vw;
  background-color: rgba(217, 217, 217, 0.5);
  /* background-color: white; */

  min-height: 4vw;
  max-height: 6vw;
  width: 17vw;
  overflow-y: auto;
}

.upper-left-section .rsw-editor:last-child {
  margin: 0 1.5vw;
  font-size: 0.8vw;
  background-color: rgba(217, 217, 217, 0.5);
  /* background-color: white; */

  min-height: 2vw;
  max-height: 3vw;
  min-width: 2vw;
  max-width: 4vw;
  width: 17vw;
  overflow-y: hidden;
  margin-top: 1vw;
  /* overflow-y: auto; */
}

/* .vertical-flex{
    display: flex;
    flex-direction: column;
  } */

.upper-right-section .rsw-editor {
  margin: 0 1.5vw;
  font-size: 0.8vw;
  background-color: rgba(217, 217, 217, 0.5);
  /* background-color: white; */

  min-height: 1.5vw;
  max-height: 1.5vw;
  width: 17vw;
  overflow-y: auto;
  /* overflow-y: hidden; */

  margin-bottom: 0.25vw;
}

.q-lower-section {
  /* flex-basis: calc(100% ); Full width for the third item */
  width: 43vw;
  height: 8vw; /* Height specifically for the third grid item */
  /* margin: 1vw; */
  margin-top: 0vw;
  margin-left: 2vw;
  /* background-color: #ccc; */
  /* background-color: red; */
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  /* background-color: #D9D9D9; */
}

.q-lower-section > h5 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 0.9vw;
  padding: 0vw 0 0.5vw 1.5vw;
  margin-block-start: 0;
  margin-block-end: 0;
}

.q-lower-section .rsw-editor {
  margin: 0 1.5vw;
  font-size: 0.8vw;
  background-color: rgba(217, 217, 217, 0.5);
  /* background-color: white; */

  min-height: 14vw;
  max-height: 15vw;
  width: 40vw;
  overflow-y: auto;
}

.content-save-btn {
  display: flex;
  align-items: end;
  flex-direction: column;
}

/* .content-save-btn > button {
  width: auto;
  text-align: center;
  padding: 0.5vw 1vw;
  background-color: #8f00ff;
  border: none;
  border-radius: 5px;
  color: white;
  font-family: Poppins;
  font-size: 0.7vw;
  align-self: flex-end;
  margin-right: 2.5vw;
  margin-top: 0.5vw;
  width: 6vw;
  cursor: pointer;
} */
/* 
  .q-lower-section> .preview-data {
    min-height: 7vw;
    max-height: 7vw;
    width: 25vw;
    background-color: white;
    margin-left: 2vw;
    margin-bottom: 2vw;
    margin-top: 0vw;
  } */

.questions-enter-btn {
  display: flex;
  align-items: end;
  flex-direction: row-reverse;
}

.questions-enter-btn > button {
  width: auto;
  text-align: center;
  padding: 0.5vw 1vw;
  background-color: #8f00ff;
  border: none;
  border-radius: 5px;
  color: white;
  font-family: Poppins;
  font-size: 0.7vw;
  align-self: flex-end;
  margin-right: 1.5vw;
  margin-top: 0.5vw;
  width: 6vw;
  cursor: pointer;
}

.q-lower-section > .preview-data {
  min-height: 7vw;
  width: 39vw;
  /* background-color: white; */
  background-color: #D9D9D9;
  border-radius: 5px;
  margin-left: 1.25vw;
  margin-top: 0vw;
  padding: 0vw 0 0.5vw 0.5vw;
  margin-block-start: 0;
  margin-block-end: 0;
  /* margin-block-start: 0;
    margin-block-end: 0; */
  overflow-y: auto;
  /* overflow: scroll; */
}

.questions-save-btn {
  display: flex;
  align-items: end;
  flex-direction: row;
  margin-top: 1vw;
}

.questions-save-btn > button {
  width: auto;
  text-align: center;
  padding: 0.5vw 1vw;
  background-color: #8f00ff;
  border: none;
  border-radius: 5px;
  color: white;
  font-family: Poppins;
  font-size: 0.7vw;
  align-self: flex-end;
  margin-right: 1.5vw;
  margin-top: 0.5vw;
  width: 6vw;
  cursor: pointer;
}
.questions-save-btn > button:first-child {
  margin-left: 30vw;
}
.questions-save-btn > button:last-child {
  margin-right: 2.5vw;
}

.q-lower-section > .preview-data > span {
  display: flex;
  flex-direction: column;
  font-size: 1.125vw;
  font-family: Arial, sans-serif;
  color: black;
}
.questions-save-btn .disabled ,
.questions-enter-btn .disabled {
  /* background-color: grey; */
  color: #ffffff;
  cursor: not-allowed;
}