.ad-left-sidebar-bg{
    background-color: #8F00FF;
    width: 13vw;
    /* height: 44vw; */
    /* min-height: 46.5vw; */
    /* max-height: 46.5vw; */
    /* max-height: 100%; */
    height: 100vh;
    min-height: 100%;
    max-height: 100%;
    overflow-y: auto;
}
        
.ls-logo-container {
    display: flex;
    justify-content: center;
    height: 2vw;
    width: 10vw;
    padding-bottom: 2.5vw;
}

.ls-logo-container>.ls-cpd-logo{
    cursor: pointer;
    width: 6vw;
    height: 2vw;
    padding-top: 1.5vw;
    /* padding-left: 3vw; */
    padding-bottom: 1vw;
}


.ls-button{
    padding-top: 2vw;
    padding-left: 1vw;
    align-content: center;
    display: flex;
        /* Add this line */
        align-items: center;
        /* Center items vertically */
}

.ls-button img{
    width: 2vw;
    height:2vw;
    margin-left: 1vw;
    margin-right: 1vw;
}

.ls-button span{
    font-family: "Roboto-Medium", Helvetica;
    color: #FFFFFF;
    font-size: 1vw;
    font-weight: 500;
}