.un-bg {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* background-color: green; */
    /* padding: 1vw; */
    background-color: #C5C5C5;
}

.nav-link {
    color: #8E00FD;
    text-decoration: none;

    /* padding: 1vw; */
}

.nav-link:first-child{
    margin-left: 5.5vw;
}
/* .nav-link:first-child{
    margin-left: 5vw;
} */

.nav-link:hover {
    text-decoration: underline;
}

.separator {
    color: #8E00FD;
    
    /* padding: 0 1vw; */
}
/* .separator:first-child {
        margin-left: 5.5vw;
} */