.general-information-form {
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    height: 23vw;
    /* Takes up full height of parent box */
}

.general-information-form h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333;
}

/* Adjust the spacing of form controls */
.general-information-form .MuiFormControl-root {
    margin-bottom: 12px;
}

.general-information-form .MuiFormControlLabel-root {
    margin-right: 10px;
}
