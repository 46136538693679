.right-sidebar-bg {
  width: 16vw;
}
.right-upper-section {
  height: 8vw;
  border-radius: 20px;
  background-color: white;
  margin: 0 0 0.5vw 0.5vw;
  margin-bottom: 1vw;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.15);
}

.events-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 4vw;
  border-radius: 20px;
  background-color: #5937c8;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.15);
}

.events-box span {
  color: white;
  padding-top: 1vw;
}

.event-notification {
  height: 4vw;
  border-radius: 20px;
  background-color: white;
  box-shadow: 2px 2px 10px 0px rgba(12, 1, 1, 0.15);
}

.event-notification p {
  padding-left: 1.5vw;
}

.right-lower-section {
  height: 27vw;
  border-radius: 20px;
  background-color: #5937c8;
  margin: 0 0 1vw 0.5vw;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.right-lower-section span {
  color: white;
}

.taskbox-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.task-box {
  flex-direction: column;
  display: flex;
  align-items: center;
  background-color: white;
  height: 7vw;
  border-radius: 20px;
  width: 175%;
  margin: 0.25vw 0;
}

.task-box span {
  color: black;
}

.scrollable-list {
  overflow-y: auto;
  max-height: 4vw;
  width: 90%;
  display: flex;
  flex-direction: column;
  padding-top: 0.5vw;
  gap: 0.4vw;
}

.scrollable-list button {
  border: 1px solid #ccc;
  height: 3vw;
  border-radius: 8px;
  background-color: #f0f0f0;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
