.view-certificate-container>.header 
{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2b2b2b;
  width: 100vw;
  padding: 1vw 0vw;
}

.view-certificate-container>.header  > span {
  color: white;
  font-family: Poppins;
  font-size: 1vw;
  padding-left: 3vw;
}

.buttons-section 
{
  display: flex;
  align-items: center;
  gap: 1vw;
  padding-right: 3vw;
}

.download-button,.close-button {
  width: 8vw;
  padding: 0.8vw;
  border-radius:50px;
  border: 1px solid white;
  background-color: #2b2b2b;
  color: white;
  font-family: Poppins;
  font-size: 0.9vw;
  cursor: pointer;
}

.download-button span{
    width: 1vw;
}

.download-button > img 
{
  width: 1.5vw;    
}

.certificate-image {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #242424;
  height: 33vw;
}

.certificate-image>img 
{
  width: 40vw;
  height: auto;
  margin: auto;
}
