.que-preview-bg {
  height: 28vw;
  width: 42vw;
  background-color: white;
  margin-left: 1.5vw;
  margin-top: 0.75vw;
  overflow-y: scroll;
  scrollbar-color: #8f00ff;
  scroll-behavior: smooth;
  scrollbar-width: 1vw;
}

.hor-flex {
  display: flex;
  flex-direction: row;
}

.vert-flex{
  display: flex;
  flex-direction: column;
}

.ind-que {
  /* height: 11vw; */
  width: 40vw;
  /* background-color: grey; */
  margin-top: 0.5vw;
  margin-bottom: 1vw;
  border-radius: 1vw;
  border-color: grey;
  height: auto;
}

.ind-que-stat {
  font-size: 1.25vw;
  font-family: "popins";
  margin-right: 1vw;
  width: 37vw;
}

.ind-que-opt,
.ind-que-ans {
  font-size: 1vw;
  font-family: "popins";
  width: 37vw;
}

.ind-que-button-edit {
  /* margin-left: auto;
    margin-right: 0.5vw;
    width: 4vw;
    height: 2vw;
    border-radius: 1vw;
    background-color: #8F00FF; */
  background: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
  /* color: white; */
  width: 1vw;
  /* width: 40px; */
  height: 1vw;
  /* height: 16px; */
  /* background-color: #9B9B9B; */
  /* border-radius: 0.5vw; */
  background-image: url('../../images/admin/single_edit.png');
  background-size: cover;
  margin-top: 0.25vw;
}

.ind-que-button-del {
  /* margin-left: auto;
    margin-right: 0.5vw;
    width: 4vw;
    height: 2vw;
    border-radius: 1vw;
    background-color: #8F00FF; */
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 0.8vw;
  /* color: white; */
  width: 1vw;
  /* width: 60px; */
    height: 1vw;
  /* height: 16px; */
    /* background-color: red; */
    /* border-radius: 0.5vw; */
  background-image: url('../../images/admin/single_delete.png');
  background-size: cover;
  margin-top: 0.25vw;
}

.edit-que-btn{
    width: 4vw;
    height: 2vw;
}

.delete-que-btn{
  width: 5vw;
    height: 2vw;
}