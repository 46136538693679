.submit-modal-bg
{
    position: absolute;
    z-index: 2;
    width: 40vw;
    right: 0;
    height: 100vh;
    background: #D9D9D9;
    display: flex;
    justify-content: center;
    align-items: center;
}
.submit-modal-bg>div
{
    width: 25vw;
    height: 25vw;
    background-color: white;
    box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.submit-modal-bg>div>h1
{
    font-family: Poppins;
    font-size: 1.3vw;
    font-weight: 800;
}
.submit-modal-bg>div>p
{
    font-family: Poppins;
    font-size: 1vw;
    width: 18vw;
    font-weight: 400;
    text-align: center;
}
.submit-btns
{
    display: flex;
    justify-self: center;
    gap: 1vw;
    margin-top: 2vw;
}
#submit-proceed, #submit-cancel
{
    width: 9vw;
    padding: 0.8vw;
    border: none;
    border-radius: 50px;
    background-color: #8E00FD;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
    font-family: Poppins;
    font-size: 0.8vw;
    color: white;
    cursor: pointer;
}
#submit-cancel
{
    background-color: #CACACA;
}
