.courseManagement-bg{
    display: flex;
    /* flex-direction: row; */
    /* align-items: end; */
    /* gap: 1vw; */
    /* padding-left: 7vw; */
    height: 48vw;
}

.management-section{
    width: 78vw;
}

.management-section>h2{
    /* font-family: Poppins;
    font-weight: 800;
    font-size: 2vw; */
    padding: 0 0 0 2.5vw;
}