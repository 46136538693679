.comments-goals-form {
    padding: 15px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    height: 23vw;
}

.quill-container {
    max-height: 100px;
    /* Set the max height */
    margin-bottom: 20px;
    /* Added bottom margin for spacing */
}

.editor-label {
    margin-bottom: 10px;
    /* Clear space below the label */
}

.upload-button {
    margin-top: 15px;
    /* Spacing above the button */
    padding: 10px 20px;
    /* Adjust padding for a better button shape */
    border-radius: 5px;
    /* Rounded corners */
}

.signature-preview {
    width: 200px;
    /* Fixed width for the preview image */
    height: 80px;
    /* Fixed height for the preview image */
    object-fit: cover;
    /* Maintain aspect ratio and cover the box */
    border: 1px solid #000;
    border-radius: 5px;
    /* Rounded corners for the image */
    /* margin-top: 10px; */
    /* Ensure space above the preview image */
}

.signature-canvas {
    border: 1px solid black;
    border-radius: 5px;
}