.lesson-container-bg{
    display: flex;
    overflow-y: hidden;
}

.lesson-bg
{
    width: 86.5vw;
    height: auto;
    background-color: rgb(223, 219, 219);
    position: absolute;
    z-index: 1;
    /* position: relative; */
    display: flex;
    flex-direction: column;
}
.darken
{
    /* background-color: #000000af !important; */
}
.lesson-bg h1
{
    font-family: Poppins;
    font-size: 2.vw;
    padding: 0.5em 0 0 1em;
    margin-block-start: 0;
}
.lesson-bg>div
{
    display: flex;
    justify-content: space-evenly;
    height: 30vw;
    /* max-height: 70vh; */
    background-color: rgb(223, 219, 219);
    margin-top: 1em;
    padding-bottom: 0.2vw;
}
#course-content
{
    background-color: white;
    overflow-y: scroll;
    width: 35vw;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: flex-start;
    padding: 1em 2.5em;
}
#course-content p:first-child
{
    font-family: Poppins;
    font-size: 1.8vw;
    font-weight: 600;
}
#sub-topics p:first-child
{
    font-family: Poppins;
    font-size: 1vw;
    font-weight: 300;
    line-height: 1.8vw;
    text-align: justify;

}
/* #sub-topics p:nth-child(2)
{
    font-family: Poppins;
    font-size: 0.9em;
    text-align: justify;
} */
#course-questions
{
    width: 35vw;
    background-color: white;
    overflow-y: scroll;
    padding: 1vw 2vw;
}
.question
{
    font-family: Poppins;
    font-size: 1vw;
    font-weight: 600;
    padding: 1em 2.5em 0 2.5em;
    margin-top: 2em;
}

.option
{
    font-family: Poppins;
    font-size: 0.9vw;
    font-weight: 500;
    padding: 1em 2.5em 1em 1em;
}

.nextLessonButton, .previousLessonButton
{
    width: 20%;
    padding: 1em;
    background-color: #8E00FD;
    font-family: Poppins;
    font-size: 0.9em;
    color: white;
    border: none;
    border-radius: 50px;
    margin: 3em 1em 1em 1em;
    cursor: pointer;
}
.previousLessonButton
{
    float: left;
}
.nextLessonButton
{
    float: right;
}
.disabled
{
    cursor: not-allowed;
    background-color: #6c557d;
}