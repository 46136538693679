.my-cert-bg{
  display: flex;
  overflow-y: hidden;
}
.certificates-bg {
  display: flex;
  flex-direction: column;
}

.header-section {
  height: 8vw;
}
.header-section > h1
{
  font-family: Poppins;
  padding-left: 4vw;
  margin-block-end: 0;
  font-size: 2vw;
}
.header-section > p 
{
  font-family: Poppins;
  padding-left: 4vw;
  font-size: 1vw;
}

.certificates-bg > hr {
  width: 70vw;
  height: 1px;
  background-color: #cacaca;
  margin-inline-start: 4vw;
  margin-inline-end: 0;
}
.menu-section {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 1vw 2vw;
}
.menu-section > div:first-child {
  width: 72vw;
  height: 24vw;
  display: flex;
  overflow-y: auto;
}
.certificates-container {
  display: flex;
  flex-wrap: wrap;
}

.certificates-container > .certificate {
  width: 20vw;
  height: 12vw;
  margin: 0.75vw;
  /* padding-left: 2vw;  */
}

.certificates-container > .certificate > img {
  width: 100%;
  height: 100%;
  border: solid 0.5px black;
}

.certificates-container > .certificate > span {
  color: white;
}
.preview
{
  width: 50vw;
  height: 50vw;
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.preview>img:first-child
{
  width: 100%;
  border: solid 1px black;
}
.preview>img:nth-child(2)
{
  width: 5%;
  position: absolute;
  z-index: 2;
  right: 0%;
  top: 15%;
}
.certificate-preview 
{
  display: flex;
  width: 100%;
  gap: 1vw;
  justify-content: center;
  align-items: center;
}

.certificate-preview >button 
{
  width: 7vw;
  padding: 0.5vw 0.8vw;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-family: Poppins;
  font-size: 0.9vw;
}

.certificates-container > .certificate-hover {
  width: 20vw;
  height: 12vw;
  margin: 0.75vw;
  background-color: #272727;
  display: flex;
}
.certificates-container > .certificate-hover > .buttons {
  cursor: pointer;
  height: 3vw;
  width: 6vw;
  padding-left: 0.75vw;
  display: flex;
  flex-direction: row;
}

.certificates-container > .certificate-hover > .buttons > button {
  border-radius: 22vw;
  display: flex;
  flex-direction: column;
}

/* .menu-section > div:nth-child(2) {
  width: 18vw;
  height: 24vw;
  background-color: #dbd9d9bf;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-section > div:nth-child(2) > img {
  background-color: white;
  height: 7vw;
  width: 7vw;
  margin-top: 2vw;
  border-radius: 50%;
}

.menu-section > div:nth-child(2) > span {
  padding-top: 0.75vw;
}

.menu-section > div:nth-child(2) > h4 {
  margin-right: 2vw;
}
.menu-section > div:nth-child(2) > .course-stat {
  display: flex;
  flex-direction: row;
}

.menu-section > div:nth-child(2) > .course-stat > span:nth-child(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 10vw; 
  margin-top: 0.5vw;
  margin-right: 1vw;
 }

 .menu-section > div:nth-child(2) > .course-stat >.score {
    align-items: center;
    font-weight: bold; 
    max-width: 7vw;
    margin-top: 0.5vw;
    margin-left: 1vw;
  }

 */

