.course-struct-bg {
  width: 86.5vw;
  height: 38.5vw;
  background-color: #d9d9d9;
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
}

.course-struct-left-container,
.course-struct-right-container {
  width: 30vw;
  height: 33vw;
  background-color: white;
  box-shadow: 3px 3px 7px 3px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-bottom: 1.5vw;
}
.course-struct-right-container {
  width: 45vw;
}

.course-struct-left-container {
  overflow-y: auto;
  overflow-x: hidden;
}

.course-struct-left-container > .header {
  height: 4vw;
  width: 30vw;
  /* background-color: black; */
  border-radius: 10px;
  box-shadow: 3px 3px 7px 3px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  /* margin-top: 0.5vw; */
  /* margin-right: 0.5vw; */
  /* margin-left: 0.5vw; */
}
.course-struct-left-container > .header > span {
  font-size: 1.25vw;
  align-items: center;
  margin-top: 1vw;
  margin-left: 2vw;
  overflow-y: hidden;
  max-height: 1.5vw;
  max-width: 8vw;
}
.course-struct-left-container > .header > .add-clik {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  /* align-items: center;
  position: relative; */
}
.course-struct-left-container > .header > .add-clik > span,
.course-struct-left-container>.header>.add-clik>.disabled {
  font-size: 1.125vw;
  margin-left: 1vw;
  margin-top: 1vw;
  color: #c5c5c5;
}
.course-struct-left-container > .header > .add-clik > img,
.course-struct-left-container>.header>.add-clik>.disabled >img {
  height: 1.5vw;
  width: 1.5vw;
  margin-top: 1vw;
  margin-left: 13vw;
}
.course-struct-left-container>.header>.add-clik> .disabled {
   /* background-color: grey; */
   color: #ffffff;
   cursor: not-allowed;
 }

/* .course-struct-left-container > .header > span:last-child {
  font-size: 1.125vw;
  margin-left: 0.5vw;
  color: #c5c5c5;
} */

.course-struct-body {
  min-height: 28.87vw;
  max-height: 28.87vw;
  width: 30vw;
  border-radius: 0.5vw;
  box-shadow: 3px 3px 7px 3px rgba(0, 0, 0, 0.25);
  /* border-radius: 1vw;
  box-shadow: 3px 3px 7px 3px rgba(0, 0, 0, 0.25); */
  margin-top: 0.125vw;
  background-color: #ffffff;
  /* background-color: black; */
  overflow-y: auto;
}

.one-module {
  height: 5vw;
  width: 30vw;
  /* margin-left: 1vw; */
  /* background-color: gray; */
  border-radius: 0.5vw;
  display: flex;
  flex-direction: row;
  border: 1px solid #cacaca;
  cursor: pointer;
}

.one-module > img {
  width: 8vw;
  height: 3.5vw;
  background-color: white;
  margin-top: 0.75vw;
  margin-left: 1vw;
  border-radius: 2vw;
}

.one-module > .module-vertical-flex {
  display: flex;
  flex-direction: column;
  width: 19vw;
  height: 3.5vw;
  margin-top: 0.75vw;
  margin-left: 1vw;
  background-color: white;
  border-radius: 2vw;
  position: relative;
  overflow: hidden;
  /* align-items: flex-start; */
  /* align-items: center; */
}
.one-module > .module-vertical-flex > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.one-module > .module-vertical-flex > span:first-child {
  color: black;
  font-family: Poppins;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 500;
  margin-left: 1vw;
  margin-top: 0vw;
}

.one-module > .module-vertical-flex > span:last-child {
  color: black;
  font-family: Poppins;
  font-size: 1vw;
  font-style: normal;
  font-weight: 300;
  margin-left: 1vw;
  margin-top: 0vw;
}
.one-lesson {
  height: 3vw;
  width: 30vw;
  /* margin-left: 2vw; */
  /* background-color: gray; */
  border-radius: 0.5vw;
  border: 1px solid #cacaca;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.one-lesson > span:first-child {
  color: black;
  font-family: Poppins;
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  margin-left: 3vw;
  margin-top: 0vw;
}

.one-lesson > span:last-child {
  color: black;
  font-family: Poppins;
  font-size: 0.75vw;
  font-style: normal;
  font-weight: 300;
  margin-left: 3vw;
  margin-top: 0vw;
}

.add-lesson {
  height: 3vw;
  width: 30vw;
  /* margin-left: 2vw; */
  /* background-color: gray; */
  border-radius: 0.5vw;
  border: 1px solid #cacaca;

  cursor: pointer;
  display: flex;
  flex-direction: row;
}
.add-lesson > span {
  font-size: 1.125vw;
  margin-left: 1vw;
  margin-top: 1vw;
  color: #c5c5c5;
}

.add-lesson > img {
  height: 1.5vw;
  width: 1.5vw;
  margin-top: 1vw;
  margin-left: 3vw;
}


