.all-courses-bg
{
    display: flex;
    flex-direction: column;
    /* background-color: lightseagreen; */
    height: 28vw;
    overflow-y: scroll;
}
.all-courses
{
    display: flex;
    justify-content: space-around;
    padding: 0.5vw 4vw 0.5vw 0vw;
    gap: 3vw;
    cursor: pointer;
    margin-left: 1vw;
}
.course-tile>img
{
    width: 18vw;
    height: 12vw;
    border-radius: 10px;
}
.all-courses>div>h2
{
    font-family: Poppins;
    font-weight: 600;
    font-size: 1.3vw;
}
.all-courses>div>p
{
    width: 40vw;
    text-align:justify;
    font-family: Poppins;
    font-weight: 400;
    font-size: 0.9vw;
}
.course-divider
{
    width: 68vw;
    height: 1px;
    background-color: #CACACA;
    margin-inline-start: 1vw;
    margin-inline-end: 0;
    margin-left: 3vw;
}
.course-status
{
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 2vw;
}
.course-status>button
{
    width: 10vw;
    padding: 0.5vw;
    background-color: #8F00FF;
    border: none;
    border-radius: 50px;
    font-family: Poppins;
    font-size: 0.7vw;
    color: white;
    cursor: pointer;
}
.course-status>p
{
    font-family: Poppins;
    font-size: 0.7vw;
    color: green;
    width: 10%;
}
