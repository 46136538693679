.add-module-bg{
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

.css-13xfq8m-MuiTabPanel-root
{
    padding: 0 !important;
}
.rsw-editor
{
    border: none !important;
}

#module-title-editor .rsw-editor{
    margin: 0 1.5vw;
    background-color: rgba(217, 217, 217, 0.50);
    min-height: 0.5vw;
    max-height: 2.5vw;
    /* overflow-y: auto; */
}

#module-desc-editor .rsw-editor
{
    margin: 0 1.5vw;
    font-size: 0.8vw;
    background-color: rgba(217, 217, 217, 0.50);
    min-height: 12vw;
    max-height: 14vw;
    overflow-y: auto;
}
#module-pass-criteria .rsw-editor
{
    background-color: rgba(217, 217, 217, 0.50);
    min-height: 2.5vw;
    max-height: 2.5vw;
    width: 4vw;
    margin-left: 1.5vw;
}

.rsw-toolbar
{
    display: none !important;
}

.image-uploader
{
    display: flex;
    align-items: end;
    gap: 1vw;
}

.image-uploader> div
{
    width: 13vw;
    height: 7vw;
    border-radius: 10px;
    margin-left: 1.5vw;
    margin-top: 1vw;
    background-color: rgba(217, 217, 217, 0.50);
    display: flex;
    justify-content: center;
    align-items: center;
}



.image-uploader>button
{
    width: auto;
    text-align: center;
    padding: 0.5vw 1vw;
    background-color: #8F00FF;
    border: none;
    border-radius: 5px;
    color: white;
    font-family: Poppins;
    font-size: 0.7vw;
}

.add-module-bg>h5{
    font-family: Poppins;
    font-weight: 600;
    font-size: 0.9vw;
    padding: 1.5vw 0 0.5vw 1.5vw;   
    margin-block-start: 0;
    margin-block-end: 0;
}

.module-save-btn{
    display: flex;
    align-items: end;
    flex-direction: row;

    margin-top: 1vw;
}

.module-save-btn>button{
    width: auto;
    text-align: center;
    padding: 0.5vw 1vw;
    background-color: #8F00FF;
    border: none;
    border-radius: 0.5vw;
    color: white;
    font-family: Poppins;
    font-size: 0.7vw;
    align-self: flex-end;
    margin-right: 1vw;
}

.module-save-btn>button:first-child {
    margin-left: 35vw;
}
.module-save-btn>button:last-child {
    margin-right: 2vw;
    background-color: red;
}

.module-save-btn  .disabled {
     /* background-color: grey; */
     color: #ffffff;
     cursor: not-allowed;
 }