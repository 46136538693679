.thumbnail-bg
{
    width: 50vw;
    height: 30vw;
    background-color:white;
    border-radius: 10px;
    box-shadow: 3px 3px 3px 4px #a9a7a7;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    top: 20%;
    left: 25%;
  }
.title-div
{
    width: 45%;
    height: 90%;
    background-color: #8F00FF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:flex-start;
}
.title-div>img
{
    width: 20vw;
    margin-top: 1vw;
}
.title-div>p
{
    font-family: Poppins;
    font-size: 2.5vw; 
    color: white;
    font-weight: 600;
    padding-left: 0.5em;
    padding-bottom: 1em;
    line-height: 1.2em;
    overflow: hidden;
    white-space: normal;
    word-wrap: break-word;
}
.right-div
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 45%;
}
.right-div>p:first-child
{
    font-family: Poppins;
    font-size: 1.2vw;
    margin-top: 5vw; 
}
.right-div>p~ p
{
    font-family: Poppins;
    font-size: 1.5vw;
    font-weight: 800;
    text-align: center;
    overflow: hidden;
    white-space: normal;
    word-wrap: break-word;
}
.resume, .start
{
    width: 50%;
    padding: 1vw 0.5vw;
    background-color: #8F00FF;
    margin-top: 5vh;
    border-radius: 30px;
    border: none;
    color: white;
    font-family: Poppins;
    font-size: 1vw;
    cursor: pointer; 
}
  
.resume:hover,
.start:hover 
{
  background-color: #5d0399; 
}
.close-thumbnail
{
  width: 2vw;
  position: absolute;
  top: 5%;
  right: 5%
}

.ti {
    font-family: Poppins;
    font-size: 2.5vw;
    color: white;
    font-weight: 600;
    padding-left: 0.5em;
    padding-bottom: 1em;
    line-height: 1.2em;
    /* Adjust to truncate long titles */
    overflow: hidden;
    text-overflow: ellipsis;
    /* Adds ellipsis (...) for overflow */
    white-space: nowrap;
    /* Prevents wrapping */
}