.reset-pw-bg
{
        background-color: #8F00FF ;
        height: 100vh;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
}
.resetPW-form
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 30vw;
    padding: 4em 1em 4em 1em;
    position: absolute;
    border-radius: 20px;
    box-shadow: 3px 3px 10px 10px #6b24a1;
}
.reset-header
{
    font-family: Poppins;    
    font-size: 1.6em;
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
}
.resetPW-form>p
{
    font-family: Poppins;    
    font-size: 0.8em;
}
#verifyMail
{
    width: 50%;
    text-align: center;
    font-family: Poppins;
    color: #47A30F;
    padding-top: 1em;
}
.proceed-btn
{
    min-width: 40%;
    cursor: pointer;
    padding: 0.8em;
    background-color: #8F00FF;
    border-radius: 50px;
    font-family: Poppins;
    font-size: 0.9em;
    color: white;
    border: none;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin: 1.5em 0 0 0;
}

#email-bar,
#password-bar {
    position: relative;
    display: flex;
    justify-content: center;
    width: 25vw;
    margin-top: 2vh;
}

.password-field {
    /* margin-top: 0.5vw; */
    margin-bottom: 0.5vw;
    /* margin-top: 0.5vw; */
}

.password-field label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

.password-mismatch-text {
    color: red;
    font-size: 0.875rem;
    margin-top: 4px;
}

#password-bar {
    position: relative;
}

.password-toggle-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}