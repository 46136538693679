.user-utilities-bg
{
    height: 3vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: lightsalmon; */
    gap: 2vw;
}
.left-utilities
{
    width: 50%;
    /* background-color: thistle; */
    margin-left: 1.9vw;
    margin-top: 1vw;
    display: flex;
    align-items: center;
    gap: 1vw;
}
.left-utilities>div
{
    min-width: 7vw;
    padding: 0.5vw 0.7vw;
    background-color: #D9D9D9;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.8vw;
    cursor: pointer;
}
#plus-icon,.dropdown-icon,#export-icon,#filter-icon
{
    width: 1.2vw;
}
.left-utilities>div>span, .right-utilities>div>span
{
    font-size: 0.8vw;
}
.left-utilities>p
{
    font-size: 0.8vw;
}


.right-utilities
{
    width: 50%;
    margin-right: 2vw;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1vw;
}
.right-utilities> div
{
    min-width: 6vw;
    padding: 0.5vw 0.7vw;
    background-color: #D9D9D9;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.8vw;
    cursor: pointer;
}
.right-utilities>div:first-child
{
    border-radius: 0;
    background-color: white;
    margin-right: 0 !important;
}
.right-utilities>div:last-child
{
    background-color: #8F00FF;
}
