.mdl-list-container-bg{
    display: flex;
    overflow-y: hidden;
}

.modules-list-bg 
{
    height: 30vw;
    /* background-color: coral; */
    margin: 0 1vw;
}
.modules-list-bg>p
{
    margin-block-start: 0;
    margin-block-end: 0;
    font-family: Poppins;
    font-weight: 800;
    font-size: 2vw;
    padding: 3vw 0 0 2vw;
} 
.underline
{
    width: 50vw;
    height: 0.4vw;
    background: #D9D9D9;
    margin: 0.5vw 0 0 2vw;
}
.modules-list-bg >hr
{
    width: 80vw;
    height: 0.5px;
    background: rgba(202, 202, 202, 0.75);
    margin-inline-start: 1vw;
    margin-inline-end: 0;  
    /* margin: 2vw 0 0 2vw;  */
}
.all-modules-bg
{
    height: 28vw;
    width: 80vw;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    margin: 1vw 0 1vw  1.5vw;
}
.all-modules
{
    display: flex;
    gap: 2vw;
    justify-content: space-around;
    margin-top: 1vw;
    width: 70vw;
    cursor: pointer;
}
.disabled-course
{
    filter: grayscale(100%);
    cursor: not-allowed;
}
.module-status
{
    display: flex;
    justify-content: end;
    font-family: Poppins;
    color:#47A30F
}
.module-tile>img
{
    width: 20vw;
    height: 12vw;
    border-radius: 10px;
}
#module-heading
{
    font-family: Poppins;
    font-size: 1.4vw;
    font-weight: 800;
}
#module-description
{
    font-family: Poppins;
    font-size: 0.9vw;
    width: 50vw;
    text-align: justify;
    
}
.course-divider1
{
    width: 75vw;
    height: 1px;
    background-color: #CACACA;
    margin-inline-start: 1vw;
    margin-inline-end: 0;
}