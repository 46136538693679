.user-management-bg {
    display: flex;
    overflow-y: hidden;
    /* Ensure content doesn't overflow */
}

.left-section {
    width: 20vw;
    /* Adjust width as needed */
    min-width: 200px;
    /* Minimum width */
    background-color: #8f00ff;
}

.um-right-section {
    width: 80vw;
    /* Adjust width as needed */
    /* overflow: hidden; */
    /* Ensure overflow is hidden to prevent layout issues */
}

.um-right-section>h1 {
    font-weight: 600;
    margin-block-start: 0;
    padding: 0 0 0 1.5vw;
}

.usersList-container {
    width: 80vw;
    margin: 2vw;
    border-radius: 0.5vw;
    background-color: #dfdfdf;
    padding: 1.5vw;
    overflow-y: auto;
    /* Enable vertical scrolling */
    /* max-height: calc(100vh - 20vw - 3vw); */
    min-height: 30vw;
    max-height: 30vw;
    /* Adjust height to leave space for header and padding */
    position: relative;
    /* Position relative for absolute positioning */
}

.usersList-title {
    padding: 1vw 0 0 1.2vw;
    color: #8f00ff;
    font-size: 1.2vw;
    font-weight: 600;
}

.export-button {
    position: absolute;
    top: 1vw;
    /* Adjust top position */
    /* right: 1rem; */
    /* Adjust right position */
    width: 5vw;
    height: 2.5vw;
    border-radius: 1vw;
    border: none;
    padding: 8px 16px;
    background-color: #8f00ff;
    color: white;
    font-size: 1vw;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.export-button:hover {
    background-color: #5f0080;
    /* Adjust hover background color */
}

.data-grid {
    background-color: white;
    border-radius: 1vw;
    height: 100%;
    /* Ensure grid fills its container */
}

.status-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

/* Customized scrollbar */
::-webkit-scrollbar {
    width: 0.5vw;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 1vw;
}

::-webkit-scrollbar-thumb {
    background: #8f00ff;
    border-radius: 1vw;
}

::-webkit-scrollbar-thumb:hover {
    background: #5f4474;
}