.admin-navbar-bg
{
    height: 3vw;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 1vw;
    margin-top: 0.5vw;
    margin-right:0vw;
}
.adminNotif-bg
{
    /* width: 2.5vw;
    height: 2.5vw; */
    padding: 0.8vw;
    background-color: #8F00FF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.adminNotif-bg>img
{
    width: 1vw;
    height: 1vw;
}

.profile-div
{
    width: 2.5vw;
    height: 2.5vw;
    background-color: antiquewhite;
    border-radius: 50%;
}