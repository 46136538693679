/* Notification.css */
/* Notification.css */

.notification-icon {
    position: relative;
    cursor: pointer;
  }
  
  .notification-icon img {
    position: absolute;
    width: 3vw; /* Adjust the size of the notification icon */
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
  }
  
  .notification-dot {
    position: absolute;
    top: 1vw;
    right: 1vw;
    width: 1vw;
    height: 1vw;
    background-color: red;
    border-radius: 50%;
  }
  
  .notification-dropdown {
    position: absolute;
    top: 6vw;
    right: 2vw;
    width: 15vw; /* Adjust the width of the dropdown */
    background-color: white;
    border: 1px solid #ccc;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1; */
  }
  
  .notification-item {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
  }
  
  .notification-item:last-child {
    border-bottom: none;
  }
  
.notification-dropdown {
    max-height: 200px; /* Adjust the maximum height for scrollability */
    overflow-y: auto;
  }
  
  .notification-item {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
  }
  
  .notification-item:last-child {
    border-bottom: none;
  }
  
  .notification-item.unread {
    background-color: white;
  }
  
  .notification-item.read {
    background-color: #f0f0f0; /* Adjust the color for read notifications */
  }
  